import React, { useState } from 'react';
import { Dropdown, Menu, Button, Icon, Modal, message, Input } from 'antd';
import moment from 'moment';
import uid from 'uid';
import handleCancellation from './handleCancelation';
import handleComment from './handleComment';
import Comments from './Comments';

import { StatusTxt } from './styles';

const handleMenuClick = (key, cadence, cadenceData, setcadenceData, user, setScriptModalVisible, setScriptContent) => {
    const currentCadenceIndex = cadenceData.data.findIndex(c => c.uid === cadence.uid);

    if(key === 'delete') {
        Modal.confirm({
            title: 'Excluir ação',
            content: 'Deseja realmente excluir esta ação?',
            okText: 'Excluir',
            cancelText: 'Cancelar',
            onOk: () => {
                setcadenceData({...cadenceData, data: cadenceData.data.filter(c => c.uid !== cadence.uid) });
            },
        });
        return;
    }

    if(key === 'canceled') {
        handleCancellation(key, cadence, cadenceData, setcadenceData, user);
        return;
    }

    if(key === 'include_comment') {
        handleComment(key, cadence, cadenceData, setcadenceData, user);
        return;
    }

    if(key === 'include_before' || key === 'include_after') {
        Modal.confirm({
            title: 'Defina a atividade',
            content: (
                <Input
                    id="activityInput"
                    placeholder="Digite a atividade"
                />
            ),
            okText: 'Confirmar',
            cancelText: 'Cancelar',
            onOk: () => {
                const activity = document.getElementById('activityInput').value;
                const newCadence = {
                    date: cadence.date,
                    type: activity,
                    status: 'pending',
                    uid: uid(20),
                };
                if (key === 'include_before') {
                    setcadenceData({...cadenceData, data: [...cadenceData.data.slice(0, currentCadenceIndex), newCadence, ...cadenceData.data.slice(currentCadenceIndex)]});
                } else {
                    setcadenceData({...cadenceData, data: [...cadenceData.data.slice(0, currentCadenceIndex + 1), newCadence, ...cadenceData.data.slice(currentCadenceIndex + 1)]});
                }
            },
        });
        return;
    }

    if (key === 'view_script') {
        setScriptContent(cadence.script || 'Nenhum script disponível');
        setScriptModalVisible(true);
        return;
    }

    const updatedCadenceData = cadenceData.data.map(c => {
        if (c.uid === cadence.uid) {
            if (key === 'done') {
                c.status = 'done';
                c.done_at = moment().format('DD/MM/YYYY HH:mm:ss');
                c.done_by = user.id;
            } else if (key === 'pending') {
                c.status = 'pending';
                c.done_at = null;
            } else if (key === 'canceled') {
                c.status = 'canceled';
                c.done_at = null;
                c.canceled_at = moment().format('DD/MM/YYYY HH:mm:ss');
                c.canceled_by = user.id;
            }
        }
        return c;
    });

    setcadenceData({ ...cadenceData, data: updatedCadenceData });
};

const CadenceDropdown = ({ cadence, cadenceData, setcadenceData, user, usersById }) => {
    const [scriptModalVisible, setScriptModalVisible] = useState(false);
    const [scriptContent, setScriptContent] = useState('');

    const menu = (
        <Menu
            onClick={({ key }) => handleMenuClick(key, cadence, cadenceData, setcadenceData, user, setScriptModalVisible, setScriptContent)}
        >
            <Menu.Item key="pending" disabled={cadence.status === 'pending'}>Pendente</Menu.Item>
            <Menu.Item key="canceled" disabled={cadence.status === 'canceled'}>Sem sucesso</Menu.Item>
            <Menu.Item key="done" disabled={cadence.status === 'done'}>
                Concluído
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="include_before">Incluir ação antes</Menu.Item>
            <Menu.Item key="include_after">Incluir ação depois</Menu.Item>
            <Menu.Item key="include_comment">Incluir comentário</Menu.Item>
            <Menu.Item key="view_script">Consultar script</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="delete">Excluir</Menu.Item>
        </Menu>
    );

    const getStatusTxt = (status) => {
        if (status === 'done') {
            return 'Concluído';
        }
        if (status === 'canceled') {
            return 'Sem sucesso';
        }
        return 'Pendente';
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <StatusTxt
                size="small"
                type="dashed"
                status={cadence.status}
            >
                {getStatusTxt(cadence.status)}
            </StatusTxt>
            {cadence.comments && (
                <Button
                    style={{
                        marginLeft: 5,
                        marginTop: 10,
                        padding: 0,
                        width: 30,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    type="dashed"
                    size="small"
                    onClick={() => {
                        Modal.info({
                            title: 'Comentários',
                            content: (
                                <Comments comments={cadence.comments} usersById={usersById} />
                            ),
                            okText: 'Fechar',
                            onOk() {},
                        });
                    }}
                >
                    <Icon type="message" />
                </Button>
            )}
            <Dropdown overlay={menu} trigger={['click']}>
                <Button
                    style={{
                        marginLeft: 5,
                        marginTop: 10,
                        padding: 0,
                        width: 30,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    type="dashed"
                    size="small"
                >
                    <Icon type="down" />
                </Button>
            </Dropdown>
            <Modal
                title="Script"
                visible={scriptModalVisible}
                onCancel={() => setScriptModalVisible(false)}
                footer={[
                    <Button key="copy" onClick={() => {
                        navigator.clipboard.writeText(scriptContent);
                        message.success('Script copiado para a área de transferência');
                    }}>
                        <Icon type="copy" /> Copiar
                    </Button>,
                    <Button key="close" onClick={() => setScriptModalVisible(false)}>
                        Fechar
                    </Button>
                ]}
            >
                <Input.TextArea
                    rows={10}
                    value={scriptContent}
                    readOnly
                />
            </Modal>
        </div>
    );
};

export default CadenceDropdown;
