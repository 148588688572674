import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Upload, Table, Select, Steps, Icon, message } from "antd";
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';

import { batch } from "../actions";

const { Step } = Steps;

const { Dragger } = Upload;
const { Option } = Select;

const systemIndex = {
    'Nome': 'name',
    'E-mail': 'email',
    'Telefone': 'phone',
    'Instagram': 'instagram',
    'Nome da Empresa': 'company_name',
    'CNPJ': 'cnpj',
    'Funcionários:': 'employees',
    'Cidade': 'city',
    'Estado': 'state',
};

const ImportModal = ({ visible, onCancel }) => {
    const dispatch = useDispatch();

    const [fileList, setFileList] = useState([]);
    const [data, setData] = useState([]);
    const [mapping, setMapping] = useState({});
    const [step, setStep] = useState(0);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleUpload = ({ file }) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const workbook = XLSX.read(e.target.result, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(sheet);
            setData(json);
            setColumns(json[0] ? Object.keys(json[0]) : []);
            setStep(1);
        };
        reader.readAsArrayBuffer(file);
        setFileList([file]);
    };

    function swapKeyValue(mapping) {
        const swapped = {};
        for (const [key, value] of Object.entries(mapping)) {
            swapped[value] = key;
        }
        return swapped;
    }

    const handleMappingChange = (value, key) => {
        const updatedMapping = { ...mapping, [key]: value };
        setMapping(updatedMapping);

        const updatedMappingSwapped = swapKeyValue(updatedMapping);

        const updatedData = data.map((row) => {
            return Object.keys(row).reduce((acc, fieldKey) => {
                if (updatedMappingSwapped[fieldKey] === undefined) {
                    acc[fieldKey] = row[fieldKey];
                    return acc;
                }

                acc[systemIndex[updatedMappingSwapped[fieldKey]]] = row[fieldKey];
                return acc;
            }, {});
        });

        setData(updatedData);
        
    };

    const handleSubmit = () => {
        setLoading(true);

        const updatedData = data.map((lead) => {
            const observation = Object.keys(lead)
                .filter(key => !Object.values(systemIndex).includes(key))
                .map(key => `${key}: ${lead[key]}`)
                .join('\n');

            return {
                ...lead,
                observation,
                entity_type: 'socialSelling',
                column_id: 1,
                board_id: 1,
            };
        });

        dispatch(batch({ leads: updatedData }))
            .then(() => {
                setLoading(false);
                setStep(0);
                setFileList([]);
                setData([]);
                setMapping({});
                onCancel();
            })
            .catch(() => {
                message.error('Erro ao importar leads');
                setLoading(false);
            });
    };

    return (
        <Modal
            visible={visible}
            title="Importar leads"
            onCancel={onCancel}
            onOk={step === 2 ? handleSubmit : () => {
                if(data && data.length) {
                    setStep(step + 1);
                } else {
                    message.warning('Selecione um arquivo para continuar');
                }
            }}
            okText={step === 2 ? 'Importar' : 'Próximo'}
            confirmLoading={loading}
            width={900}
        >
            <Steps current={step} style={{ marginBottom: 16 }}>
                <Step title="Upload" />
                <Step title="Identificar Colunas" />
                <Step title="Confirmar" />
            </Steps>
            {step === 0 && (
                <Dragger
                    beforeUpload={() => false}
                    onChange={handleUpload}
                    fileList={fileList}
                >
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Clique ou arraste o arquivo para esta área para fazer o upload</p>
                </Dragger>
            )}
            {step === 1 && (
                <div style={{ marginTop: 16, textAlign: 'center' }}>
                    {Object.keys(systemIndex).map((key, indexSys) => (
                        <div key={key} style={{ marginBottom: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={{ marginRight: 8, textAlign: 'right', width: 150 }}>{key}: </span>
                            <Select
                                style={{ width: 200 }}
                                onChange={(value) => handleMappingChange(value, key)}
                                placeholder="Selecione uma coluna"
                                // defaultValue={columns.includes(key) ? key : undefined}
                            >
                                {columns.map((col) => (
                                    <Option key={col} value={col}>
                                        {col}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    ))}
                </div>
            )}
            {step === 2 && (
                <>
                    <Table 
                        dataSource={data} 
                        columns={Object.keys(systemIndex).map(key => ({
                            title: key,
                            dataIndex: systemIndex[key],
                            key: systemIndex[key],
                            width: 150, // Set a width for each column
                            ellipsis: true, // Add ellipsis to prevent row breaks
                        }))}
                        rowKey={(record, index) => index} 
                        pagination={true}
                        scroll={{ x: '100%' }} // Enable horizontal scrolling
                        style={{ width: '100%' }} // Set table width to 100%
                    />
                </>
            )}
        </Modal>
    );
};

export default ImportModal;