import { PERMISSIONS } from '~/constants/permissions.constants';

export const getAllowedDepartments = (user, permissions) => {
  if (!user || !permissions) return [];

  const permissionsList = [
    {
      key: PERMISSIONS.checklist.columns.legalization,
      department: "1",
    },
    {
      key: PERMISSIONS.checklist.columns.administrative,
      department: "2",
    },
    {
      key: PERMISSIONS.checklist.columns.accounting,
      department: "3",
    },
    {
      key: PERMISSIONS.checklist.columns.fiscal,
      department: "4",
    },
    {
      key: PERMISSIONS.checklist.columns.personal,
      department: "5",
    },
    {
      key: PERMISSIONS.checklist.columns.cs,
      department: "6",
    },
  ];

  let userPermissions = [];

  if (!user.permissions_group_id) {
    userPermissions = user.permissions;
  } else {
    const permissionsGroup = (userPermissions = permissions.find(
      (item) => item.id === user.permissions_group_id
    ));
    if (!permissionsGroup) return [];
    userPermissions = permissionsGroup.permissions;
  }

  return permissionsList
    .filter((item) => userPermissions.includes(item.key))
    .map((item) => item.department);
};

export const getTaskPercentage = (record, department, allowedDepartments) => {
  if (!allowedDepartments.includes(department)) return 0;

  const tasks = record.tasks.filter((task) => task.department === department);
  if(tasks.length === 0){
    return 100;
  }
  
  const concludedTasksAmount = tasks.filter((task) => task.done).length;
  const percentage =
    Math.ceil((100 * concludedTasksAmount) / tasks.length) || 0;

  return percentage;
};

export const getTaskTotalPercentage = (record, allowedDepartments) => {
  const allowedTasks = record.tasks.filter((task) =>
    allowedDepartments.includes(task.department)
  );
  const concludedTasksCount = allowedTasks.filter((task) => task.done).length;
  const concludedTasksPercentage = Math.ceil(
    (100 * concludedTasksCount) / allowedTasks.length
  );

  return concludedTasksPercentage;
};

export const getPercentageColor = (percentage) => {
  if (percentage < 30) return "#f5222d";
  else if (percentage < 60) return "#d4b106";
  else if (percentage < 99) return "#2f54eb";
  else return "#389e0d";
};

export const getStatusName = (status) => {
  const names = {
    1: "Encerramento",
    2: "Entrada",
    3: "Troca (Saída)",
    4: "Troca (Entrada)",
  };

  return names[status];
};

export const getStatusStyle = (status) => {
  const colors = { 1: "#f5222d", 2: "#a0d911", 3: "#fa8c16", 4: "#1890ff" };

  return {
    borderColor: colors[status],
    color: colors[status],
  };
};

export const getChecklistStatusName = (status) => {
  return {
    1: "Saída (Encerramento)",
    2: "Entrada",
    3: "Saída (Troca de Contabilidade)",
    4: "Entrada (Troca de Contabilidade)",
  }[status];
}