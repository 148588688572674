/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Icon } from 'antd';
import { Button, Drawer, Table, Upload, Modal, Divider, Form, Input, Select, Collapse, Tooltip } from 'antd';
import { DrawerBody, DrawerFooter } from '~/components/UI/Drawer';
import Attachments from '~/containers/Attachments';
import { copyToClipBoard } from '~/utils/copy-to-clipboard';
import { updateSolicitation, deleteSolicitation } from '../actions';
import { useDispatch } from 'react-redux';

const { Dragger } = Upload;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;

const ViewSolicitation = ({ 
    data, 
    onClose, 
    onDelete,
    form
}) => {
    if (!data) return '';

    const { solicitation_type, description, uid } = data;
    const { getFieldDecorator } = form;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Você tem certeza?',
            content: 'Essa ação não poderá ser desfeita.',
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Não',
            onOk() {
                // deleteSolicitation(id).then(() => {
                //     onDelete(id);
                // });
            },
        });
    }

    const handleSubmit = () => {
        form.validateFields((err, values) => {
            if (err) return;

            setLoading(true);

            values = {
                ...data,
                ...values,
            };

            dispatch(updateSolicitation(data.id, values)).then(() => {
                setLoading(false);
                onClose(false);
            });
        });
    };

    const copyFeature =  {
        render: (_, record) => (
        <Tooltip title={'Clique para copiar'}>
            <span onClick={() => copyToClipBoard(_)} style={{cursor: 'pointer'}}>
                {_}
            </span>
        </Tooltip>
    )};

    const columns = [
        {
            title: 'Nome do tomador',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...copyFeature
        },
        {
            title: 'CPF/CNPJ',
            dataIndex: 'doc',
            key: 'doc',
            width: '20%',
            ...copyFeature
        },
        {
            title: 'Cód',
            dataIndex: 'cod',
            key: 'cod',
            width: '15%',
            ...copyFeature
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
            width: '20%',
            ...copyFeature
        },
        {
            title: 'Valor',
            dataIndex: 'amount',
            key: 'amount',
            width: '20%',
            ...copyFeature
        },
    ];

    return (
        <Drawer
            title="Responder Solicitação"
            placement="right"
            closable={true}
            onClose={onClose}
            width={800}
            visible
            footer={
                <div style={{ textAlign: 'right' }}>
                    <Button key="delete" type="primary" danger onClick={() => handleDelete(data.id)}>
                        <Icon type="delete" /> Deletar
                    </Button>
                    <Button key="close" onClick={() => onClose(false)}>
                        Fechar
                    </Button>
                </div>
            }
        >
            <DrawerBody>
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Detalhes da Solicitação" key="1">
                        <div style={{ marginBottom: 30 }}>
                            <p><strong>Empresa:</strong> {data.customer ? `${data.customer.cod} - ${data.customer.name}` : ''}</p>
                            <p><strong>Usuário:</strong> {data.app_user_id ? data.customer.app_users.find(appUser => appUser.id === data.app_user_id).name : ''}</p>
                            <p><strong>Tipo de Solicitação:</strong> {solicitation_type}</p>
                            <p><strong>Descrição:</strong> {description}</p>
                        </div>
                        {Array.isArray(data.custom_data) && data.custom_data.length > 0 && data.solicitation_type === 'NF-e' && (
                            <>
                                <Divider orientation="left">Notas Fiscais a serem emitidas</Divider>
                                <Table
                                    dataSource={data.custom_data}
                                    columns={columns}
                                    pagination={false}
                                    rowKey="key"
                                    bordered={false}
                                    size='small'
                                />
                            </>
                        )}
                        <Attachments
                            source={'solicitations'}
                            entity={uid}
                            initialFiles={[]}
                            canUpload={false}
                            canDelete={false}
                        />
                    </Panel>
                    <Panel header="Resposta" key="2">
                        <Form>
                            <Form.Item label="Status">
                                {getFieldDecorator('status', {
                                    initialValue: data.status,
                                    rules: [{ required: true, message: 'Por favor, selecione o status!' }],
                                })(
                                    <Select>
                                        <Option value={0}>Pendente</Option>
                                        <Option value={1}>Concluído</Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="Resposta">
                                {getFieldDecorator('awnser', {
                                    initialValue: data.awnser,
                                    rules: [{ required: true, message: 'Por favor, insira sua resposta!' }],
                                })(<TextArea rows={4} />)}
                            </Form.Item>
                            <Attachments
                                source={'solicitations'}
                                entity={uid + '_response'}
                                initialFiles={[]}
                            />
                        </Form>
                    </Panel>
                </Collapse>
            </DrawerBody>
            <DrawerFooter>
                <Button key="close" onClick={() => onClose(false)}>
                    Fechar
                </Button>
                {' '}
                <Button 
                    key="save" 
                    type="primary" 
                    onClick={handleSubmit} 
                    loading={loading}>
                    Salvar
                </Button>
            </DrawerFooter>
        </Drawer>
    );
};

export default Form.create()(ViewSolicitation);