import React from 'react';
import { Form, Row, Col, Select, Input, Tooltip, Icon } from 'antd';
import InputMask from '~components/UI/InputMask';
import InputCurrency, { currency2Float } from '~components/UI/InputCurrency';
import get from 'lodash/get';
import { removeCountryCodeFromPhone } from '~/utils/formatters';
import { WhatsappIcon } from '~/icons/WhatsappIcon';
import { WhatsappLink } from './styles';

const Details = ({ form, data, columns }) => {
    const { getFieldDecorator, getFieldValue } = form;

    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Nome do Sócio">
                        {getFieldDecorator('name', {
                            initialValue: get(data, 'name'),
                            rules: [{ required: true, message: 'Por favor informe o nome do sócio!' }],
                        })(<Input placeholder="Nome do sócio" />)}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Etapa do Funil">
                        {getFieldDecorator('column_id', {
                            initialValue: get(data, 'column_id', 1),
                            rules: [{ required: true, message: 'Por favor informe a etapa do funil!' }],
                        })(
                            <Select>
                                {columns.map(column => (
                                    <Select.Option key={column.id} value={column.id}>
                                        {column.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Nome da Empresa">
                        {getFieldDecorator('company_name', {
                            initialValue: get(data, 'additional_data.company_name'),
                        })(<Input placeholder="Nome da empresa" />)}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="CNPJ">
                        {getFieldDecorator('cnpj', {
                            initialValue: get(data, 'additional_data.cnpj'),
                        })(<InputMask mask="99.999.999/9999-99" placeholder="CNPJ" />)}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="WhatsApp">
                                {getFieldDecorator('phone', { initialValue: removeCountryCodeFromPhone(get(data, 'additional_data.phone')) })(
                                    <InputMask
                                        mask="(99) 9999tt999?"
                                        formatChars={{ "9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]" }}
                                        maskChar={null}
                                        placeholder="WhatsApp"
                                        suffix={getFieldValue('phone') ?
                                            <Tooltip title="Ir para o Whatsapp" placement="topRight">
                                                <WhatsappLink href={`https://wa.me/55${getFieldValue('phone')}`} target="_blank">
                                                    <WhatsappIcon />
                                                </WhatsappLink>
                                            </Tooltip> : <span />
                                        }
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="E-mail">
                                {getFieldDecorator('email', {
                                    initialValue: get(data, 'additional_data.email'),
                                })(<Input placeholder="E-mail" />)}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Instagram">
                                {getFieldDecorator('instagram', {
                                    initialValue: get(data, 'additional_data.instagram'),
                                })(
                                    <Input
                                        placeholder="Instagram"
                                        addonBefore={<Icon type="instagram" />}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item label={<span>Faturamento</span>}>
                        {getFieldDecorator('billing', {
                            initialValue: get(data, 'additional_data.billing'),
                            normalize: (value) => currency2Float(value)
                        })(
                            <InputCurrency />
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={<span>Honorário</span>}>
                        {getFieldDecorator('honorary', {
                            initialValue: get(data, 'additional_data.honorary'),
                            normalize: (value) => currency2Float(value)
                        })(
                            <InputCurrency />
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={<span>Funcionários</span>}>
                        {getFieldDecorator('employees', {
                            initialValue: get(data, 'additional_data.employees'),
                        })(
                            <Input />
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Cidade">
                        {getFieldDecorator('city', {
                            initialValue: get(data, 'additional_data.city'),
                        })(<Input placeholder="Cidade" />)}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Estado">
                        {getFieldDecorator('state', {
                            initialValue: get(data, 'additional_data.state'),
                        })(<Input placeholder="Estado" />)}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label="Observação">
                        {getFieldDecorator('observation', {
                            initialValue: get(data, 'additional_data.observation'),
                        })(<Input.TextArea rows={6} placeholder="Observação" />)}
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default Details;