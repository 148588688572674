import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { Card, Drawer, Tabs, Icon } from 'antd';
import { convertToBRL } from '~/utils/formatters';
import { LastUpdatedAt } from '../styles';
import CustomerTable from './CustomerTable';
import { getChartOptions } from './useChartOptions';

export const CustomersCountChart = ({ data, isLoading, lastUpdatedAt }) => {
  if (!data) return null;
  
  const months = Object.keys(data);
  const inAmounts = months.map(month => data[month].in_amount);
  const outAmounts = months.map(month => data[month].out_amount);
  const [drawerVisible, setDrawerVisible] = React.useState(false);

  const cardTitle = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h3 style={{ margin: 0 }}>Quantidade de clientes</h3>
      <Icon type="info-circle" onClick={() => setDrawerVisible(true)} style={{ cursor: 'pointer', fontSize: 16 }} />
    </div>
  );

  const renderDrawerContent = () => {
    if (months.length > 1) {
      return (
        <Tabs>
          {months.map(month => (
            <Tabs.TabPane tab={moment(`${month}-01`).format('MMM/YYYY')} key={month}>
              <CustomerTable monthData={data[month]} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      );
    }
    return <CustomerTable monthData={data[months[0]]} />;
  };

  return (
    <Card loading={isLoading} title={cardTitle}>
      <Chart
        height={350}
        options={getChartOptions(data, months, inAmounts, outAmounts)}
        series={[
          { name: 'Entrada de clientes', color: '#52c41a', type: 'column', data: months.map(m => data[m].in) },
          { name: 'Saída de clientes', color: '#cf1322', type: 'column', data: months.map(m => data[m].out) },
          { name: 'Quantidade de clientes', color: '#e8b627', type: 'line', data: months.map(m => data[m].total) },
          { name: 'Churn', color: '#000', type: 'line', data: months.map(m => data[m].churn) },
        ]}
      />
      <Drawer
        title="Relatório de entrada e saída de clientes"
        placement="right"
        closable
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={800}
      >
        {renderDrawerContent()}
      </Drawer>
      {lastUpdatedAt && <LastUpdatedAt>{lastUpdatedAt}</LastUpdatedAt>}
    </Card>
  );
};
export default CustomersCountChart;
