import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Tag, Input, Menu, Icon, Dropdown, Select, Tooltip, Button, Modal, message } from 'antd';
import get from 'lodash/get';
import { FaWhatsapp } from 'react-icons/fa';

import moment from 'moment';
import 'moment/locale/pt-br';

import * as malhaFinaActions from '~/containers/MalhaFina/actions';
import { capitalizeFormatter, identificationNumberFormatter } from '~/utils/formatters'
import { getAllMonths } from './methods/getAllMonths';
import { getIRPFMonths } from './methods/getIRPFMonths';
import { ModuleHeader } from '~/components/ModuleHeader';
import { CellButton, CellButtonsWrapper, HeaderContainer, HeaderLeftContent } from './styles';
import { EmailsLogsModal } from './components/EmailsLogsModal';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;
const Search = Input.Search;
const { Option } = Select;

function getBackgroundColorCell(status){
  if(status !== null){
    if(status === 1){
      return '#1B76FF';
    }else if(status === 2){
      return '#FF0000';
    }else{
      return '#36BD2C';
    }
  }else{
    return '#bfbfbf';
  }
}

function getWhatsappIconColor(isWhatsappSent) {
  return isWhatsappSent ? '#1B76FF' : '#FFF';
}

function getEmailIconColor(isEmailSent, isEmailViewed) {
  if (isEmailViewed) {
    return '#36BD2C';
  }

  if (isEmailSent) {
    return '#1B76FF';
  }

  return '#FFF';
}

const List = (props) => {

  const [search, setSearch] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSendingEmailsInBatch, setIsSendingEmailsInBatch] = useState(false);
  const [emailsLogsModal, setEmailsLogsModal] = useState({
    isOpen: false,
    data: null
  });

  const {
    changeStatus,
    year,
    setYear,
    deleteRegister,
    deleteAllRegisterOfMonth,
    isLoading,
    sendWhatsapp,
    sendEmail,
    sendEmailsInBatch
  } = props;

  const { department } = props.match.params;

  const data = props.data[department];

  let filtered = (data !== null)? data.filter((str) => {

    if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
      return true;
    }

    if(str.cod.toString().toLowerCase().indexOf(search.toLowerCase()) >= 0){
      return true;
    }

    // if(str.identification_number.toLowerCase().indexOf(search.toLowerCase()) >= 0){
    //   return true;
    // }

    return false;
  }): [];

  const years = (back) => {
    const currentyear = new Date().getFullYear();
    return Array.from({length: back}, (v, i) => currentyear - back + i + 1);
  }

  const allMonths = getAllMonths(year);
  const IRPFMonths = getIRPFMonths(year);

  const months = department !== 'IRPF' ? allMonths : IRPFMonths;

  useEffect(() => {
    setSelectedRows([]);
  }, [department]);

  const openEmailsLogsModal = (data) => {
    setEmailsLogsModal({
      isOpen: true,
      data
    });
  };

  const closeEmailsLogsModal = () => {
    setEmailsLogsModal({
      isOpen: false,
      data: null,
    });
  };

  function handleDelete({
    id,
    monthBase,
    monthYear
  }) {
    Modal.confirm({
      title: 'Você realmente deseja remover este registro?',
      content: 'Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => {
        deleteRegister(department, {
          id,
          month_base: monthBase,
          year: monthYear
        });
      },
    });
  }

  function handleDeleteMonth(month, index) {
    const hasSomeFilledRegister = filtered.some((item) => {
      const monthIndex = department !== 'IRPF' ? Number(month.base) - 1 : index;
      return item[`date${monthIndex}`] !== null;
    });

    if (hasSomeFilledRegister) {
      Modal.confirm({
        title: `Você realmente deseja remover todos os registros do mês de ${month.fullText}?`,
        content: 'Esta ação não poderá ser desfeita.',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => {
          deleteAllRegisterOfMonth(department, {
            month_base: month.base,
            year: month.year
          })
        },
      });
    } else {
      message.warning('Não existem registros nesse mês para serem removidos.')
    }
  }

  async function handleSendEmailInBatch() {
    setIsSendingEmailsInBatch(true);
    try {
      await sendEmailsInBatch({
        department,
        year,
        month: moment().format('MM'),
        ids: selectedRows.map(({ id }) => id)
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsSendingEmailsInBatch(false);
    }
  }

  async function handleOpenWhatsapp({ record, month, isWhatsappSent }) {
    const { phone, id } = record;

    if (!phone) {
      return message.warning('O cliente não possui um telefone cadastrado!');
    }

    if (!isWhatsappSent) {
      await sendWhatsapp(
        department,
        {
          id,
          month_base: month.base,
          year: month.year
        }
      );
    }

    const whatsappMessage = `Encontramos pendências em âmbito ${department}, entre em contato para regularizar.`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=55${phone}&text=${whatsappMessage}`;
    window.open(whatsappUrl, "_blank").focus();
  }

  async function handleSendEmail({ record, month, isEmailSent }) {
    const { email, id } = record;

    if (!email) {
      return message.warning('O cliente não possui um e-mail cadastrado!');
    }

    if (isEmailSent) {
      openEmailsLogsModal({
        id,
        month_base: month.base,
        year: month.year
      });
    } else {
      await sendEmail(
        department,
        {
          id,
          month_base: month.base,
          year: month.year
        }
      );
    }
  }

  return (
    <div>
        <div style={{ 
            margin: `0px -16px`, 
            padding: '15px', 
            background: '#fff', 
            marginBottom: `10px` }}>
            <ModuleHeader
              breadcrumbs={['Dep. Societário', 'Malha fiscal', capitalizeFormatter(department)]}
              title="Malha fina"
              search={
                <Search
                  placeholder="Pesquisar cliente"
                  onChange={e => setSearch(e.target.value)}
                  style={{ maxWidth: 300 }}
                />
              }
              actions={
                <>
                <Button
                  disabled={selectedRows.length === 0 || isSendingEmailsInBatch}
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={handleSendEmailInBatch}
                >
                  Enviar e-mails
                </Button>
                <Select
                  style={{ maxWidth: 100 }}
                  value={year}
                  placeholder="Selecione um ano"
                  onChange={(value) => setYear(value)}
                >
                  {years(parseInt(moment().format('YYYY'))-2018).map(row  => 
                  <Option value={row} key={row}>{row}</Option>
                  )}
                </Select>
                </>
              }
            />
        </div>
        <div className="table-clabs">
          <Table
            dataSource={filtered}
            rowKey="id"
            loading={isLoading}
            rowClassName="row-clabs-grid"
            rowSelection={{
              selectedRowKeys: selectedRows.map(({ id }) => id),
              onChange: (_, rows) => {
                setSelectedRows(rows);
              },
              getCheckboxProps: (record) => {
                const currentMonth = Number(moment().format('MM')) - 1;
                const isEmailSent = record[`is_email_sent${currentMonth}`] === 1;
                const hasPendencyInCurrentMonth = record[`date${currentMonth}`] === 2;
                return {
                  disabled: !hasPendencyInCurrentMonth || isEmailSent
                }
              }
            }}
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.malhaFina, 
              defaultPageSize: 1000
            })}
            bordered={true}
            scroll={{ x: true, y: 'calc(100vh - 330px)' }}
          >
            <Column
                title="Cód"
                dataIndex="cod"
                key="cod"
                width={150}
                render={cod => <Tag key={cod}>{cod}</Tag>}
                sorter={(a, b) => a.cod && a.cod.localeCompare(b.cod)}
              />
              <Column
                  title="Cliente"
                  dataIndex="name"
                  key="name"
                  width={450}
                  sorter={(a, b) => a.name && a.name.localeCompare(b.name)}
              />
              {department !== 'IRPF' && (
                <Column
                  title="CNPJ"
                  dataIndex="identification_number"
                  key="identification_number"
                  width={200}
                  render={(identificationNumber) => identificationNumberFormatter(identificationNumber)}
                />
              )}
              {department !== 'IRPF' && (
                <Column
                  title="Link"
                  dataIndex="link"
                  align="center"
                  key="link"
                  width={80}
                  render={(link) => link && (
                    <Button href={link} target="_blank" icon="link" />
                  )}
                />
              )}
              {months.map((month, index) =>
              <Column
                title={(
                  <small>
                    {month.text}
                    <Icon
                      type="delete"
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                      onClick={() => handleDeleteMonth(month, index)}
                    />
                  </small>
                )}
                dataIndex={month.base}
                key={month.base}
                width={100}
                align="center"
                className="grid-month"
                render={(_, record) => {
                  const currentRegister = department !== 'IRPF' ?
                    record[`date${Number(month.base) - 1}`] :
                    record[`date${index}`];

                  const isWithPendency = record[`date${index}`] === 2;
                  const isEmailSent = record[`is_email_sent${index}`];
                  const isEmailViewed = record[`is_email_viewed${index}`];
                  const isWhatsappSent = record[`is_whatsapp_sent${index}`];
                  return(
                  <Dropdown overlay={
                    <Menu 
                      onClick={(event) => {
                        if (event.key === 'delete') {
                          return handleDelete({
                            id: record.id,
                            monthBase: month.base,
                            monthYear: month.year
                          });
                        }

                        changeStatus(department, {
                          status: event.key,
                          id: record.id,
                          month_base: month.base,
                          year
                        })
                      }}
                      >
                      <Menu.Item key="0"><Icon type="check" /> Sem pendência</Menu.Item>
                      <Menu.Item key="2"><Icon type="issues-close" />Com pendência</Menu.Item>
                      <Menu.Item key="1"><Icon type="check-circle" /> Completo</Menu.Item>
                      {currentRegister !== null && (
                        <Menu.Item key="delete"><Icon type="delete" /> Remover</Menu.Item>
                      )}
                    </Menu>
                  } trigger={['contextMenu','click']}>
                    <Tooltip 
                      placement="bottom"
                      title={get(record, `date${index}_user`) ? `Atualizado por: ${get(record, `date${index}_user`)} em ${moment(get(record, `date${index}_performed_at`)).subtract(3, 'hours').format('DD/MM/YYYY HH:mm:ss')}`:'Ainda não foi atualizado.'}>
                      <div style={{ 
                        userSelect: 'none',
                        backgroundColor: getBackgroundColorCell(get(record, `date${index}`)),
                        height: `100%`,
                        width: `100%`,
                        position: `absolute`,
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        {isWithPendency && (
                          <CellButtonsWrapper>
                            <CellButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleSendEmail({
                                  record,
                                  month,
                                  isEmailSent
                                })
                              }}
                            >
                              <Icon 
                                type="mail"
                                style={{ color: getEmailIconColor(isEmailSent, isEmailViewed) }}
                              />
                            </CellButton>
                            <CellButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleOpenWhatsapp({
                                  record,
                                  month,
                                  isWhatsappSent,
                                });
                              }}
                            >
                              <FaWhatsapp
                                size={16}
                                style={{
                                  color: getWhatsappIconColor(isWhatsappSent),
                                }}
                              />
                            </CellButton>
                          </CellButtonsWrapper>
                        )}
                    </div>
                    </Tooltip>
                  </Dropdown>
                )}
                }
              />
              )}
          </Table>
        </div>
        <EmailsLogsModal
          isOpen={emailsLogsModal.isOpen}
          data={emailsLogsModal.data}
          onClose={closeEmailsLogsModal}
          department={department}
        />
      </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.malhaFina.data,
  isLoading: state.malhaFina.isLoading,
  findUser: (id) => state.users.filter(r=>r.id===id)[0],
});

const mapDispatchProps = dispatch => ({
  changeStatus: (department, data) => dispatch(malhaFinaActions.changeStatus(department, data)),
  deleteRegister: (department, data) => dispatch(malhaFinaActions.deleteRegister(department, data)),
  deleteAllRegisterOfMonth: (department, data) => dispatch(malhaFinaActions.deleteAllRegisterOfMonth(department, data)),
  sendWhatsapp: (department, data) => dispatch(malhaFinaActions.sendWhatsapp(department, data)),
  sendEmail: (department, data) => dispatch(malhaFinaActions.sendEmail(department, data)),
  sendEmailsInBatch: (department, data) => dispatch(malhaFinaActions.sendEmailsInBatch(department, data)),
});

export default connect(mapStateToProps, mapDispatchProps)(withRouter(List));