import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  clearAllDepartmentsCalendarState,
  loadAllDepartmentsCalendarTasks,
} from "./components/CalendarTask/actions";
import { loadCertificates } from "./components/Certificates/actions";
import { loadConventions } from "./components/Conventions/actions";
import { loadProcurations } from "./components/Procurations/actions";
import { loadTasks } from "./components/RegisterTasks/actions";
import { loadUser, loadUsers } from "./components/Users/actions";
import { ROUTES } from "./constants/routes.constants";
import { loadChecklistTasks } from './containers/Checklist/actions';
import { loadCustomers } from "./containers/Customer/actions";
import { loadDocumentTypes } from "./containers/Documents/actions";
import { loadLinkCategories, loadLinks } from "./containers/Links/actions";
import { loadPermissionsGroups } from "./containers/Permissions/actions";
import { loadEmailsLogs } from "./containers/EmailsLogs/actions";
import { loadSettings } from "./containers/Settings/actions";
import { AccountStorage } from './storages/account';
import { handleLogout } from './utils/handle-logout';
import { LocalCacheHandler } from './utils/local-cache-handler';
import { CACHE_ACTIONS } from './constants/cache-actions.constants';
import { loadPayments } from "~/screens/Financial/MonthlyPayment/actions";
import { loadSolicitations } from "~/_domains/solicitations/actions";

export const AppContext = ({ children, location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state);

  async function loadInitialCache() {
    
    const cacheActionKeys = Object.keys(CACHE_ACTIONS);

    await Promise.all(cacheActionKeys.map(async (key) => {
      const actionName = CACHE_ACTIONS[key];
      const pathname = CACHE_ACTIONS[key];

      await LocalCacheHandler.load({
        actionName,
        dispatch,
        pathname,
      });
    }));

  }

  function refreshEach5Minutes() {
    setInterval(() => {
      dispatch(loadSolicitations());
    }, 600000); // 10 minutes
  }

  useEffect(() => {
    refreshEach5Minutes();
  }, []);

  function getLoaders() {
    const loadersToCall = [];
    const loaders = [
      loadUser,
      loadSettings,
      loadPermissionsGroups,
      loadAllDepartmentsCalendarTasks,
      loadCustomers,
      loadSolicitations,
      loadCertificates,
      loadProcurations,
      loadConventions,
      loadUsers,
      loadLinks,
      loadLinkCategories,
      loadDocumentTypes,
      loadChecklistTasks,
      loadTasks,
      loadEmailsLogs,
      loadPayments
    ];

    const { pathname } = location;

    loaders.forEach((loader) => {
      const customersWillAlreadyBeCalled =
        loader.name === "loadCustomers" && [ROUTES["/clientes"], ROUTES['/lembretes']].includes(pathname);
      const permissionsGroupsWillAlreadyBeCalled =
        loader.name === "loadPermissionsGroups" &&
        pathname === ROUTES["/checklist"];
      const conventionsWillAlreadyBeCalled =
        loader.name === "loadConventions" && pathname === ROUTES["/convencoes"];
      const certificadosWillAlreadyBeCalled =
        loader.name === "loadCertificates" &&
        pathname === ROUTES["/certificados"];
      const procurationsWillAlreadyBeCalled =
        loader.name === "loadProcurations" &&
        pathname === ROUTES["/lembretes"];
      const linksWillAlreadyBeCalled =
        loader.name === "loadLinks" && pathname === ROUTES["/links"];
      const linkCategoriesWillAlreadyBeCalled =
        loader.name === "loadLinkCategories" && pathname === ROUTES["/links"];
      const tasksWillAlreadyBeCalled =
        loader.name === "loadTasks" && pathname === ROUTES["/tarefas"];

      if (
        !customersWillAlreadyBeCalled &&
        !permissionsGroupsWillAlreadyBeCalled &&
        !conventionsWillAlreadyBeCalled &&
        !certificadosWillAlreadyBeCalled &&
        !procurationsWillAlreadyBeCalled &&
        !linksWillAlreadyBeCalled &&
        !linkCategoriesWillAlreadyBeCalled &&
        !tasksWillAlreadyBeCalled
      ) {
        loadersToCall.push(dispatch(loader()));
      }
    });

    return loadersToCall;
  }

  async function loadInitialData() {
    await loadInitialCache();

    try {
      await Promise.all(getLoaders());
    } catch (error) {
      if (
        error.error &&
        error.error.response &&
        error.error.response.status === 401
      ) {
        handleLogout();
        history.replace("/login");
      }
    }
  }

  useEffect(() => {
    if (AccountStorage.get().token) {
      loadInitialData();
    } else {
      handleLogout();
      history.replace("/login");
    }

    return () => dispatch(clearAllDepartmentsCalendarState());
  }, []);

  return (
    <>
      <div className={`pageloader ${!user.id ? "is-active" : ""}`}></div>
      {children}
    </>
  );
};
