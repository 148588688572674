import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Badge } from 'antd';

const CustomerTable = ({ monthData }) => {
  // Fetch customersById from Redux state
  const customersById = useSelector(state => state.customersById);

  console.log('monthData', monthData);

  // Map in_ids and out_ids to customer details; fall back to empty string if not found.
  const customersIn = (monthData.in_ids || []).map(id => {
    const customer = customersById[id];
    return customer ? (customer.cod ? `${customer.cod} - ${customer.name}` : customer.name) : "";
  });
  const customersOut = (monthData.out_ids || []).map(id => {
    const customer = customersById[id];
    return customer ? (customer.cod ? `${customer.cod} - ${customer.name}` : customer.name) : "";
  });

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
      <div style={{ width: "50%" }}>
        <Table
          dataSource={customersIn.map((item, i) => ({ key: i, entrada: item }))}
          columns={[
            { 
              title: (
                <span>
                  Entrada de clientes <Badge count={customersIn.length} style={{ backgroundColor: '#52c41a' }} />
                </span>
              ), 
              dataIndex: 'entrada', 
              key: 'entrada',
              render: text => <span style={{ color: '#52c41a' }}>{text}</span>
            },
          ]}
          pagination={false}
          size="small"
        />
      </div>
      <div style={{ width: "50%" }}>
        <Table
          dataSource={customersOut.map((item, i) => ({ key: i, saida: item }))}
          columns={[
            { 
              title: (
                <span>
                  Saída de clientes <Badge count={customersOut.length} style={{ backgroundColor: '#cf1322' }} />
                </span>
              ), 
              dataIndex: 'saida', 
              key: 'saida',
              render: text => <span style={{ color: '#cf1322' }}>{text}</span>
            },
          ]}
          pagination={false}
          size="small"
        />
      </div>
    </div>
  );
};

export default CustomerTable;
